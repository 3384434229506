import { styled } from "@mui/material/styles";
import { forwardRef, useEffect } from "react";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";
import { AssetUrl } from "@holibob-packages/vault";

import { ConsumerTripProductList } from "../../apiHooks/useConsumerTripProductsQuery";
import { ProductItem } from "../../types";
import { ConsumerTripStoriesProductCard } from "./ConsumerTripStoriesProductCard";

export type ConsumerTripStoryContentProps = {
    stopAutoPlay: () => void;
    subtitle?: string;
    title?: string;
    productList: ConsumerTripProductList;
    onProductClick?: (item: ProductItem, index: number) => void;
    onScroll?: () => void;
    onStoryProductsDisplay: (
        productList: ConsumerTripProductList,
        shelfType: ConsumerTripContentItemProductListType
    ) => void;
    shelfType: ConsumerTripContentItemProductListType;
};

export const ConsumerTripStoryContent = forwardRef(function ConsumerTripStoryContent(
    props: ConsumerTripStoryContentProps,
    ref: any
) {
    const { stopAutoPlay, subtitle, title, productList, shelfType, onProductClick, onScroll, onStoryProductsDisplay } =
        props;
    const handleScroll = () => {
        stopAutoPlay();
        onScroll?.();
    };

    useEffect(() => {
        onStoryProductsDisplay(productList, shelfType);
    }, [onStoryProductsDisplay, productList, shelfType]);

    return (
        <>
            {subtitle && <StorySubtitle>{subtitle}</StorySubtitle>}
            {title && <StoryTitle>{title}</StoryTitle>}
            <ProductCardsContainer onScroll={handleScroll} ref={ref}>
                {productList.map((node, index) =>
                    node.previewImage ? (
                        <ConsumerTripStoriesProductCard
                            key={node.id}
                            id={node.id}
                            name={node.name}
                            price={node.holibobGuidePrice}
                            slug={node.slug}
                            onClick={() => {
                                stopAutoPlay();
                                onProductClick?.({ ...node, name: node.name ?? "" }, index);
                            }}
                            imageAssetUrl={
                                new AssetUrl({
                                    id: node.previewImage.id,
                                    type: "productImage",
                                })
                            }
                        />
                    ) : null
                )}
            </ProductCardsContainer>
        </>
    );
});

export const StoryTitle = styled("p")(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: 22,
    fontWeight: 500,
    margin: 0,
    lineHeight: "28px",
}));

export const StorySubtitle = styled("p")(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: 16,
    margin: 0,
}));

const ProductCardsContainer = styled("div")(({ theme }) => ({
    display: "flex",
    overflowX: "scroll",
    flexWrap: "nowrap",
    scrollSnapType: "x mandatory",
    gap: theme.spacing(2),
    paddingBlockStart: theme.spacing(2),
    "&::-webkit-scrollbar": {
        display: "none",
    },
}));
