"use client";

import React from "react";
import { MarkRequired } from "ts-essentials";

import { ProductClickEvent } from "../custom-events";
import { useRouter } from "../navigation/useRouter";
import { ProductSearchByPhraseLayout, ProductSearchByPhraseLayoutProps, SearchModal } from "./index";

export type ProductSearchModalProps = MarkRequired<ProductSearchByPhraseLayoutProps, "productHrefFactory"> & {
    isOpen?: boolean;
    contentRef: React.RefObject<HTMLDivElement>;
};

export const ProductSearchModal = ({
    ProductCard,
    onProductClick,
    isOpen,
    contentRef,
    productHrefFactory,
    ...props
}: ProductSearchModalProps) => {
    const router = useRouter();

    return (
        <SearchModal open={isOpen}>
            <ProductSearchByPhraseLayout
                ref={contentRef}
                ProductCard={ProductCard}
                isHeaderBackground={true}
                productHrefFactory={productHrefFactory}
                onProductClick={(event, item, index) => {
                    if (!onProductClick) {
                        event.preventDefault();
                        contentRef.current?.dispatchEvent(new ProductClickEvent({ item, position: index }));
                        router.push(productHrefFactory({ id: item.id, slug: item.slug }));
                    }
                    onProductClick?.(event, item, index);
                }}
                {...props}
            />
        </SearchModal>
    );
};
