import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRef, useState } from "react";

import {
    ProductClickEvent,
    SearchEvent,
    DestinationProductSearchClickEvent,
    SearchTriggerEvent,
    DestinationSearchEvent,
} from "../../custom-events";
import { useCustomDOMEventHandler, useNextTranslation } from "../../hooks";
import { ProductCardV2Props } from "../../product";
import { HbmlCurationProductsProductHrefFactory } from "../HbmlComponents";
import { ProductSearchModal } from "../index";

type ConsumerTripSearchProductsProps = {
    productHrefFactory: HbmlCurationProductsProductHrefFactory;
    placeId?: string | null;
    ProductCardComponent: React.ComponentType<ProductCardV2Props>;
};

export const ConsumerTripSearchProducts = ({
    productHrefFactory,
    placeId,
    ProductCardComponent,
}: ConsumerTripSearchProductsProps) => {
    const [t] = useNextTranslation("product");
    const [isOpen, setIsOpen] = useState(false);

    const contentRef = useRef<HTMLButtonElement>(null);

    const handleOpen = () => {
        setIsOpen(true);
        contentRef.current?.dispatchEvent(
            new SearchTriggerEvent({ searchButtonType: "DESTINATION_PRODUCT_SEARCH_HEADER_SEARCH_BAR" })
        );
    };

    const handleClose = () => setIsOpen(false);

    const dialogContentRef = useRef<HTMLDivElement>(null);

    useCustomDOMEventHandler(dialogContentRef, (subscription) => {
        subscription.on(SearchEvent, (event) => {
            contentRef.current?.dispatchEvent(new DestinationSearchEvent(event.phrase));
        });

        subscription.on(ProductClickEvent, (event) => {
            contentRef.current?.dispatchEvent(new DestinationProductSearchClickEvent(event.details));
        });
    });

    return (
        <>
            {isOpen && (
                <ProductSearchModal
                    placeId={placeId}
                    productHrefFactory={productHrefFactory}
                    ProductCard={ProductCardComponent}
                    isOpen={isOpen}
                    onClose={handleClose}
                    filters={{ synchronisationSources: ["VIATOR", "MUSEMENT", "TIQETS"] }}
                    contentRef={dialogContentRef}
                />
            )}
            <ConsumerSearchButton onClick={handleOpen} ref={contentRef}>
                <SearchIcon />
                <span>{t("productSearch.placeholder")}</span>
            </ConsumerSearchButton>
        </>
    );
};

const ConsumerSearchButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    borderColor: "rgba(255, 255, 255, 0.4)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 40,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 2),
    cursor: "pointer",
    marginTop: theme.spacing(),
    justifyContent: "flex-start",
    gap: theme.spacing(0.5),
    display: "none",
    fontWeight: 400,
    height: 34,
    "& span": {
        fontSize: 16,
    },
    [theme.breakpoints.up("sm")]: {
        display: "flex",
        padding: theme.spacing(0.5, 3),
    },
}));
