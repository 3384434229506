import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import { ComponentProps, ComponentType, ForwardedRef, forwardRef } from "react";
import { MarkRequired } from "ts-essentials";

import { ConsumerTripProductFragment } from "../../gql-request";
import { ProductsContainerSlider, ProductsContainerSliderProps } from "../../product/ProductsContainerSlider";
import { ProductItem } from "../../types";
import { HbmlConsumerTripProps } from "../HbmlComponents/HbmlConsumerTripNode";
import { MarketPlaceProductTileVerticalSkeleton } from "../MarketPlace/MarketPlaceTile/MarketPlaceProductTileVerticalSkeleton";
import { ScrollableContainerProps } from "../ScrollableContainer";
import { Typography, TypographyProps } from "../Typography";

type ConsumerTripShelfCommon = {
    title: string;
    subTitle?: string;
    Icon: ComponentType<ComponentProps<"svg">>;
};

export type ConsumerTripShelfProductSliderProps = MarkRequired<
    Pick<HbmlConsumerTripProps, "productHrefFactory" | "ProductCardComponent">,
    "ProductCardComponent"
> &
    Omit<ProductsContainerSliderProps, "sliderItemWidth" | "children"> & {
        maxProducts?: number;
        products: ConsumerTripProductFragment[];
        onProductClick?: (item: ProductItem, position: number) => void;
    } & ConsumerTripShelfCommon;

export const ConsumerTripShelfProductSlider = forwardRef(function Component(
    {
        title,
        subTitle,
        Icon,
        products,
        ProductCardComponent,
        productHrefFactory,
        onProductClick,
        ...otherProps
    }: ConsumerTripShelfProductSliderProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <Wrapper>
            <Header title={title} subTitle={subTitle} Icon={Icon} />
            <ProductsContainerSlider {...otherProps} ref={ref}>
                {products.map((entry, index) => (
                    <ProductCardComponent
                        {...entry}
                        key={index}
                        orientation="vertical"
                        href={productHrefFactory({ slug: entry.slug, id: entry.id })}
                        imageMaxWidth={264}
                        onProductClick={(item) => {
                            onProductClick?.(item, index);
                        }}
                    />
                ))}
            </ProductsContainerSlider>
        </Wrapper>
    );
});

type ConsumerTripShelfProductSliderSkeletonProps = ProductsContainerSliderProps & { maxProducts?: number };
export const ConsumerTripShelfProductSliderSkeleton = forwardRef(function Component(
    props: ConsumerTripShelfProductSliderSkeletonProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const _itemsCount = getSliderItemsCount(props.mode, props.maxProducts);
    const skeletons = Array.from({ length: _itemsCount });

    return (
        <Wrapper>
            <HeaderSkeleton />
            <ProductsContainerSlider {...props} ref={ref}>
                {skeletons.map((_, index) => (
                    <MarketPlaceProductTileVerticalSkeleton key={index} imageMaxWidth={264} />
                ))}
            </ProductsContainerSlider>
        </Wrapper>
    );
});

const ITEMS_COUNT_FULL_WIDTH = 10;
const ITEMS_COUNT_DEFAULT = 5;

function getSliderItemsCount(mode: ScrollableContainerProps["mode"], maxProducts?: number) {
    switch (mode) {
        case "full-width": {
            if (!maxProducts) return ITEMS_COUNT_FULL_WIDTH;
            return maxProducts > ITEMS_COUNT_FULL_WIDTH ? ITEMS_COUNT_FULL_WIDTH : maxProducts;
        }
        default: {
            if (!maxProducts) return ITEMS_COUNT_DEFAULT;
            return maxProducts > ITEMS_COUNT_DEFAULT ? ITEMS_COUNT_DEFAULT : maxProducts;
        }
    }
}

const Wrapper = styled("div")(({ theme }) => ({
    gap: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
}));

function Header({ subTitle, title, Icon }: ConsumerTripShelfCommon) {
    return (
        <HeaderWrapper>
            {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}
            <TitleWrapper>
                <IconWrapper>
                    <Icon />
                </IconWrapper>
                <Title variant="title" size={"large"} isResponsive={true} tag={"h2"}>
                    {title}
                </Title>
            </TitleWrapper>
        </HeaderWrapper>
    );
}

function HeaderSkeleton() {
    return (
        <HeaderWrapper>
            <Skeleton variant="rectangular" height={16} width={150} />
            <TitleWrapper>
                <IconSkeleton variant="rectangular" />
                <Skeleton variant="rectangular" height={24} width={250} />
            </TitleWrapper>
        </HeaderWrapper>
    );
}

const HeaderWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
}));

const SubTitle = ({ children, ...props }: TypographyProps) => {
    return (
        <Typography
            variant="title"
            size={"small"}
            isResponsive={true}
            tag={"h3"}
            fontWeight="bold"
            color="primary"
            {...props}
        >
            {children}
        </Typography>
    );
};

const TitleWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
}));

const IconWrapper = styled("div")(({ theme }) => ({
    "& svg": { width: 16, height: 16, fill: theme.palette.common.white },
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
    gridArea: "icon",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
        height: 30,
        width: 30,
        "& svg": { width: 20, height: 20 },
    },
}));

const Title = styled(Typography)({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
});

const IconSkeleton = styled(Skeleton)(({ theme }) => ({
    display: "block",
    gridArea: "icon",
    borderRadius: "50%",
    width: 32,
    height: 32,

    [theme.breakpoints.up("md")]: {
        height: 40,
        width: 40,
    },
}));
