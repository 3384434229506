import { BookingProductCardV2 } from "containers/BookingProductCardV2";
import { DistributionChannelPageContentQuery } from "gql";
import { useConsumerTripEventQuestionnaire } from "hooks/consumerTripEvent/useConsumerTripEventQuestionnaire";
import { useConsumerTripEventStory } from "hooks/consumerTripEvent/useConsumerTripEventStory";
import { useConsumerTripEventWLSearch } from "hooks/consumerTripEvent/useConsumerTripEventWLSearch";
import { useConsumerTripEventWLSearchTriggered } from "hooks/consumerTripEvent/useConsumerTripEventWLSearchTriggered";
import { usePersonalizationShelfDisplayedEvent } from "hooks/consumerTripEvent/usePersonalizationShelfDisplayedEvent";
import { usePersonalizationShelfScrolledEvent } from "hooks/consumerTripEvent/usePersonalizationShelfScrolledEvent";
import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useConsumerTripProductClickEventRegistration } from "hooks/useConsumerTripProductClickEventRegistration";
import { useProductUrlGenerator } from "hooks/useProductUrlGenerator";
import { useRef } from "react";

import { HbmlComponents, HbmlElementComponentProps } from "@holibob-packages/ui-core/components";
import {
    DestinationProductSearchClickEvent,
    ShelfDisplayEvent,
    ShelfFirstScrollEvent,
    ShelfProductClickEvent,
    StoryProductClickEvent,
    StoryProductsScrollEvent,
    SearchTriggerEvent,
    DestinationSearchEvent,
    StoryDisplayEvent,
    StoryClickEvent,
    QuestionnaireOpenEvent,
    QuestionnaireInterestSelectEvent,
    QuestionnaireInterestUnselectEvent,
    QuestionnaireNextClickEvent,
    QuestionnaireBackClickEvent,
    QuestionnaireCloseEvent,
    QuestionnaireSubmitEvent,
    QuestionnaireChangeOfHeartClickEvent,
} from "@holibob-packages/ui-core/custom-events";
import { useCustomDOMEventHandler } from "@holibob-packages/ui-core/hooks";

import { contentComponentPreset } from "./hbmlContentComponentPreset";

export type PageComponentPresetsParams = {
    distributionChannelPageContent?: DistributionChannelPageContentQuery["distributionChannelPageContent"];
    pagePath: string;
};

export const getPageComponentPresets = (params: PageComponentPresetsParams) => {
    const { distributionChannelPageContent, pagePath } = params;

    const pageContentComponentPreset = {
        ...contentComponentPreset,
        [HbmlComponents.HbmlElements.CONSUMER_TRIP]: ({ content }: HbmlElementComponentProps) => {
            const placeId = distributionChannelPageContent?.placeId;
            const heroTitle = distributionChannelPageContent?.metaTitle;
            const { consumerTripId } = useConsumerTripInfo();
            const productHrefFactory = useProductUrlGenerator();
            const personalizationShelfDisplayedEvent = usePersonalizationShelfDisplayedEvent();
            const consumerTripEventCreateShelfScrolled = usePersonalizationShelfScrolledEvent();
            const consumerTripEventProductClick = useConsumerTripProductClickEventRegistration();
            const consumerTripEventQuestionnaire = useConsumerTripEventQuestionnaire();
            const consumerTripEventStory = useConsumerTripEventStory();
            const consumerTripEventWLSearch = useConsumerTripEventWLSearch();
            const consumerTripEventWLSearchTriggered = useConsumerTripEventWLSearchTriggered();

            const ref = useRef<HTMLDivElement>(null);

            useCustomDOMEventHandler(ref, (subscription) => {
                // Story events
                subscription.on(StoryDisplayEvent, (event) => {
                    void consumerTripEventStory.consumerTripEventCreateStoryDisplayed(event.details);
                });
                subscription.on(StoryClickEvent, (event) => {
                    void consumerTripEventStory.consumerTripEventCreateStoryClicked(event.shelfType);
                });
                subscription.on(StoryProductClickEvent, (event) => {
                    const { item, position, shelfType } = event.details;
                    void consumerTripEventProductClick({
                        itemId: item.id,
                        itemPosition: position,
                        shelfType,
                        productOrigin: "STORY",
                    });
                });
                subscription.on(StoryProductsScrollEvent, (event) => {
                    void consumerTripEventStory.consumerTripEventCreateStoryScrolled(event.shelfType);
                });
                // Shelf events
                subscription.on(ShelfDisplayEvent, (event) => {
                    void personalizationShelfDisplayedEvent(event.details);
                });
                subscription.on(ShelfFirstScrollEvent, (event) => {
                    void consumerTripEventCreateShelfScrolled(event.shelfType);
                });
                subscription.on(ShelfProductClickEvent, (event) => {
                    const { item, position, shelfType } = event.details;
                    void consumerTripEventProductClick({
                        itemId: item.id,
                        itemPosition: position,
                        shelfType,
                        productOrigin: "PERSONALIZATION_SHELF",
                    });
                });
                // Search events
                subscription.on(SearchTriggerEvent, (event) => {
                    void consumerTripEventWLSearchTriggered({
                        searchButtonType: event.details.searchButtonType,
                    });
                });
                subscription.on(DestinationSearchEvent, (event) => {
                    void consumerTripEventWLSearch({
                        phrase: event.phrase,
                        searchType: "DESTINATION_PRODUCT_SEARCH",
                    });
                });
                subscription.on(DestinationProductSearchClickEvent, (event) => {
                    void consumerTripEventProductClick({
                        itemId: event.details.item.id,
                        itemPosition: event.details.position,
                        productOrigin: "DESTINATION_PRODUCT_SEARCH",
                    });
                });
                // Questionnaire events
                subscription.on(QuestionnaireOpenEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireOpened(event.details);
                });
                subscription.on(QuestionnaireInterestSelectEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireInterestSelected(event.details);
                });
                subscription.on(QuestionnaireInterestUnselectEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireInterestUnselected(event.details);
                });
                subscription.on(QuestionnaireNextClickEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireNextClicked(event.details);
                });
                subscription.on(QuestionnaireBackClickEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireBackClicked(event.details);
                });
                subscription.on(QuestionnaireCloseEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireClosed(event.details);
                });
                subscription.on(QuestionnaireSubmitEvent, () => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireSubmitted();
                });
                subscription.on(QuestionnaireChangeOfHeartClickEvent, () => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireChangeOfHeartClicked();
                });
            });

            return (
                distributionChannelPageContent && (
                    <HbmlComponents.HbmlConsumerTripNode.nodeComponent
                        pagePath={pagePath}
                        ProductCardComponent={BookingProductCardV2}
                        productHrefFactory={productHrefFactory}
                        consumerTripId={consumerTripId}
                        innerRef={ref}
                        content={content}
                        placeId={placeId}
                        heroTitle={heroTitle}
                    />
                )
            );
        },
    };

    return HbmlComponents.extendStandardPreset(pageContentComponentPreset);
};
