import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import { ConsumerTripWeatherForecastFragment } from "../../gql-request";
import { WeatherIcons } from "../WeatherIcons";
import { ConsumerTripHeroImagesTitle, ConsumerTripHeroImagesDescription } from "./ConsumerTripHeroImagesTypography";

export type ConsumerTripHeroContentProps = {
    title?: string | null;
    country: string;
    arrivalDate?: string | null;
    departureDate?: string | null;
    forecast?: ConsumerTripWeatherForecastFragment[] | null;
    showPersonalizedData: boolean;
};
export const ConsumerTripHeroContent = ({
    title,
    arrivalDate,
    departureDate,
    forecast,
    showPersonalizedData,
}: ConsumerTripHeroContentProps) => {
    const tripDateFormatted = formatTripDate({ arrivalDate, departureDate });

    return (
        <Wrapper>
            <ConsumerTripHeroImagesTitle>{title}</ConsumerTripHeroImagesTitle>

            {showPersonalizedData && (
                <>
                    {tripDateFormatted && (
                        <ConsumerTripHeroImagesDescription>{`${tripDateFormatted}`}</ConsumerTripHeroImagesDescription>
                    )}

                    <WeatherIcons forecast={forecast?.slice(0, 5)} />
                </>
            )}
        </Wrapper>
    );
};

type formatTripDate = {
    arrivalDate?: string | null;
    departureDate?: string | null;
};
function formatTripDate({ arrivalDate, departureDate }: formatTripDate) {
    const parsedArrivalDate = dayjs(arrivalDate);
    const parsedDepartureDate = departureDate ? dayjs(departureDate) : null;

    if (arrivalDate && parsedArrivalDate.isValid()) {
        if (parsedDepartureDate?.isValid()) {
            return parsedArrivalDate.format("D") + "-" + parsedDepartureDate.format("D MMM YYYY");
        } else {
            return parsedArrivalDate.format("D MMM YYYY");
        }
    } else {
        return "";
    }
}

const Wrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    flex: 1,
    maxHeight: "30%",
    minHeight: 230,
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
        gap: theme.spacing(3),
    },
}));
