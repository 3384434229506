"use client";

import { ContainerProps } from "@mui/material/Container";
import { HbmlContentRenderer } from "components/Hbml/HbmlContentRenderer";
import Layout from "components/Layout";
import { Footer } from "containers/Footer";
import { usePageView } from "containers/GoogleAnalytics/googleAnalyticsEvents";
import { Header, HeaderProps } from "containers/Header/Header";
import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import useGlobalSearchIsOpen from "hooks/useGlobalSearchIsOpen";
import { useHeaderConfiguration } from "hooks/useHeaderEntityConfiguration";
import useLoadingBlanker from "hooks/useLoadingBlanker";
import dynamic from "next/dynamic";
import { useEffect } from "react";

import { ConsumerTripEventWLPageViewCreateInput } from "@holibob-packages/consumer-trip-event";
import type { Hbml } from "@holibob-packages/hbml";

import { useDistributionChannelContentQuery } from "../../gql";

const LoadingBlanker = dynamic(() => import("components/LoadingBlanker"), { ssr: false });
const GlobalSearch = dynamic(() => import("components/GlobalSearch"), { ssr: false });

export type PageType = ConsumerTripEventWLPageViewCreateInput["meta"]["pageType"];

type PageProps = {
    children?: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    sidebar?: React.ReactNode;
    title?: string;
    itemId?: string;
    className?: string;
    type: PageType;
    /**
     * @property {boolean} allowHeaderTransparency -  set to true ONLY if the first Page element after the Header is Hero media (image / video)
     * */
    allowHeaderTransparency: boolean;
    headerConfig?: HeaderProps;
    style?: ContainerProps["style"];
};

export const Page = ({
    children,
    header: headerProp,
    footer: footerProp,
    sidebar,
    title,
    type,
    itemId,
    className,
    allowHeaderTransparency,
    headerConfig,
    style,
}: PageProps) => {
    const { consumerTripId } = useConsumerTripInfo();
    const effectiveHeaderConfig = useHeaderConfiguration({ allowHeaderTransparency, ...headerConfig });
    const pageView = usePageView();

    useEffect(() => {
        if (title) pageView(type, title, itemId); // title can be asynchronous
    }, [itemId, pageView, title, type]);

    const { data: content } = useDistributionChannelContentQuery({ variables: { consumerTripId } });

    const headerNavigator = content?.distributionChannelContent?.headerMenu;
    const footerHbmlContent = content?.distributionChannelContent?.footer?.enhanced;
    const footerHbml = footerHbmlContent && <HbmlContentRenderer content={footerHbmlContent as Hbml} />;

    const header = headerProp ?? <Header navigation={headerNavigator} {...effectiveHeaderConfig} />;
    const footer = footerProp ?? footerHbml ?? <Footer />;

    const blankerIsOpen = useLoadingBlanker();

    const globalSearchIsOpen = useGlobalSearchIsOpen();

    return (
        <>
            {blankerIsOpen && <LoadingBlanker />}
            {globalSearchIsOpen && <GlobalSearch />}
            <Layout
                className={className}
                data-testid="logoImage"
                header={header}
                footer={footer}
                sidebar={sidebar}
                style={style}
                fullWidth
            >
                {children}
            </Layout>
        </>
    );
};

export default Page;
