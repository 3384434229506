import { ProductItem } from "../types";

export class ProductClickEvent extends Event {
    static NAME = "product:click";

    constructor(
        readonly details: {
            item: ProductItem;
            position: number;
        }
    ) {
        super(ProductClickEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
