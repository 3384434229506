"use client";

import { styled } from "@mui/material/styles";
import { ForwardedRef, forwardRef, useMemo } from "react";
import { MarkRequired } from "ts-essentials";

import {
    ConsumerTripContentItemProductListLayout,
    ConsumerTripContentItemProductListType,
} from "@holibob-packages/graphql-types";
import { normalizePathPart } from "@holibob-packages/url";

import { useConsumerTripContentQuery, useConsumerTripQuery } from "../../apiHooks/graphql";
import { useConsumerTripProductsQuery } from "../../apiHooks/useConsumerTripProductsQuery";
import { ProductsContainerSliderProps } from "../../product/ProductsContainerSlider";
import { computeFinalConsumerTripShelfList } from "../../utils/storiesHelper";
import { HbmlConsumerTripProps, HeroImageConsumerTrip } from "../HbmlComponents";
import { PageLayout } from "../HbmlComponents/common/PageLayout";
import { useSectionPadding } from "../HbmlComponents/hooks/useSectionPadding";
import { HeroImages, MEDIUM_SCREEN_HEIGHT_VARIABLE } from "../HeroImages";
import { ConsumerTripHeroContent } from "./ConsumerTripHeroContent";
import { ConsumerTripShelfContainer } from "./ConsumerTripShelfContainer";
import { ConsumerTripShelfRecentlyViewed } from "./ConsumerTripShelfRecentlyViewed";
import { ConsumerTripStoriesContainer } from "./ConsumerTripStoriesContainer";

const SMALL_SCREEN_HEIGHT = 450;
const MEDIUM_SCREEN_HEIGHT = 775;

export type ConsumerTripContainerProps = {
    pagePath: string;
    heroImageAssetUrlList?: string[];
} & MarkRequired<HbmlConsumerTripProps, "ProductCardComponent"> &
    Omit<ProductsContainerSliderProps, "sliderItemWidth" | "children" | "onScroll">;

export const ConsumerTripContainer = forwardRef(function Component(
    props: ConsumerTripContainerProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { consumerTripId } = props;
    const { data: consumerTripData, loading } = useConsumerTripQuery({ variables: { consumerTripId } });
    const consumerTrip = consumerTripData?.consumerTrip;

    if (!consumerTrip && !loading) {
        if (!props.heroImageAssetUrlList?.length) {
            return null;
        }

        return (
            <HeroImagesStyled
                images={props.heroImageAssetUrlList ?? []}
                smallScreenHeight={SMALL_SCREEN_HEIGHT}
                mediumScreenHeight={SMALL_SCREEN_HEIGHT}
                data-is-rendering-stories={false}
            >
                <ConsumerTripHeroContent title={props.heroTitle} showPersonalizedData={false} country={""} />
            </HeroImagesStyled>
        );
    }

    return (
        <div ref={ref}>{consumerTrip && <ConsumerTripContainerContent {...props} consumerTrip={consumerTrip} />}</div>
    );
});

export type ConsumerTripContainerContentProps = Omit<ConsumerTripContainerProps, "consumerTripId"> & {
    consumerTrip: HeroImageConsumerTrip;
};

function ConsumerTripContainerContent(props: ConsumerTripContainerContentProps) {
    const { consumerTrip, placeId, heroImageAssetUrlList, heroTitle, ProductCardComponent, productHrefFactory } = props;
    const { consumerTripId } = consumerTrip;
    const distributionChannelPagePath = normalizePathPart(props.pagePath);
    const hasMatchingDestinationPagePath = consumerTrip.destinationPagePath
        ? normalizePathPart(consumerTrip.destinationPagePath) === distributionChannelPagePath
        : false;

    const shouldQueryConsumerTrip = !!(placeId && consumerTripId) && hasMatchingDestinationPagePath;
    const { data, loading } = useConsumerTripContentQuery({
        variables: { consumerTripId, placeId: placeId! },
        skip: !shouldQueryConsumerTrip,
    });
    const rules = data?.consumerTripContent?.itemList;

    const hasRules = rules && rules.length > 0;
    const hasPlaceId = !!placeId;
    const shouldRender = hasPlaceId && (loading || hasRules);

    const sliderRules = useMemo(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        () => rules?.filter((rule) => rule.layout === ConsumerTripContentItemProductListLayout.Slider) ?? [],
        [rules]
    );

    const dataForShelfList = useConsumerTripProductsQuery({ personalizationShelfList: sliderRules });
    const sectionPadding = useSectionPadding();
    const shelfTypeListWithData = new Set<ConsumerTripContentItemProductListType>();
    for (const [shelfType, data] of Object.entries(dataForShelfList)) {
        if (data.loading || data.data.length > 0) {
            shelfTypeListWithData.add(shelfType as ConsumerTripContentItemProductListType);
        }
    }
    const finalShelfList = Array.from(
        placeId
            ? computeFinalConsumerTripShelfList({
                  shelfList: sliderRules,
                  shelfTypeListWithData,
                  placeId: placeId,
              })
            : []
    );
    const storyShelfList = finalShelfList.slice(0, 4);
    const restShelfList = finalShelfList.slice(4);

    if (!heroImageAssetUrlList?.length) {
        return null;
    }

    return (
        <>
            <HeroImagesStyled
                images={heroImageAssetUrlList}
                smallScreenHeight={SMALL_SCREEN_HEIGHT}
                mediumScreenHeight={hasMatchingDestinationPagePath ? MEDIUM_SCREEN_HEIGHT : SMALL_SCREEN_HEIGHT}
                data-is-rendering-stories={!!shouldRender}
            >
                <ConsumerTripHeroContent
                    arrivalDate={consumerTrip.arrivalDate}
                    departureDate={consumerTrip.departureDate}
                    forecast={consumerTrip.weatherForecast}
                    title={heroTitle}
                    showPersonalizedData={hasMatchingDestinationPagePath}
                    country={consumerTrip.destinationCountryName ?? ""}
                />

                {shouldRender ? (
                    <ConsumerTripStoriesContainer
                        isLoading={loading}
                        rules={storyShelfList}
                        consumerTrip={consumerTrip}
                        consumerTripSystemTagIdList={data?.consumerTripContent?.systemTagIdList ?? []}
                        placeId={placeId}
                        productHrefFactory={productHrefFactory}
                        ProductCardComponent={ProductCardComponent}
                        country={consumerTrip.destinationCountryName ?? ""}
                        consumerName={consumerTrip.consumer?.givenName}
                        products={{
                            ...dataForShelfList,
                            PERSONAL: {
                                data: [],
                                loading: false,
                            },
                        }}
                    />
                ) : null}
            </HeroImagesStyled>

            {shouldRender ? (
                <ConsumerTripContainerWrapper style={{ ...sectionPadding }}>
                    {!!restShelfList.length && (
                        <ConsumerTripShelfContainer rules={restShelfList} products={dataForShelfList} {...props} />
                    )}

                    <ConsumerTripShelfRecentlyViewed {...props} consumerTripId={consumerTrip.consumerTripId} />
                </ConsumerTripContainerWrapper>
            ) : null}
        </>
    );
}

const HeroImagesStyled = styled(HeroImages)(({ theme }) => ({
    '&[data-is-rendering-stories="false"]': {
        marginBlockEnd: 0,

        '& [data-ref="heroCarouselOverlay"]': {
            height: "100%",

            "& .MuiContainer-root": {
                marginBlockStart: "auto",
                justifyContent: "center",
                height: "100%",
            },
        },
    },

    '&[data-is-rendering-stories="true"]': {
        // This will override the content height, but the image will still be the height of the small height provided
        height: MEDIUM_SCREEN_HEIGHT_VARIABLE.reference,
    },

    [theme.breakpoints.up("sm")]: {
        marginBlockEnd: theme.spacing(14),
    },

    "& [data-ref='heroCarouselOverlay']": {
        background: `linear-gradient(180deg, rgba(0,0,0,0.4) calc(100% - 130px), rgba(0,0,0,1))`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "bottom",
        height: "auto",

        [theme.breakpoints.up("sm")]: {
            height: "100%",
        },

        "& .MuiContainer-root": {
            maxWidth: "100%",
            gap: theme.spacing(5),
            padding: theme.spacing(0),
            marginBlockStart: theme.spacing(13),
            height: "auto",
            [theme.breakpoints.up("md")]: {
                marginBlockStart: theme.spacing(18),
            },
        },
    },
}));

const ConsumerTripContainerWrapper = styled(PageLayout)(({ theme }) => ({
    "&:has([data-ref='main-column-wrapper']:empty)": {
        display: "none",
    },
    "& [data-ref='main-column-wrapper']:empty": {
        display: "none",
    },
    "& [data-ref='divider']:last-child": {
        display: "none",
    },
    "& [data-ref='main-column-wrapper']": {
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(4),
        [theme.breakpoints.up("md")]: {
            gap: theme.spacing(6),
        },
    },
}));
