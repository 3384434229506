import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { BookingProductCardV2 } from "containers/BookingProductCardV2";
import { useConsumerTripEventWLSearch } from "hooks/consumerTripEvent/useConsumerTripEventWLSearch";
import { useConsumerTripEventWLSearchTriggered } from "hooks/consumerTripEvent/useConsumerTripEventWLSearchTriggered";
import { useConsumerTripProductClickEventRegistration } from "hooks/useConsumerTripProductClickEventRegistration";
import { useProductUrlGenerator } from "hooks/useProductUrlGenerator";
import { useRef, useState } from "react";

import { ProductSearchModal } from "@holibob-packages/ui-core/components";
import { ProductClickEvent, SearchEvent } from "@holibob-packages/ui-core/custom-events";
import { useCustomDOMEventHandler } from "@holibob-packages/ui-core/hooks";

type HeaderSearchButtonProps = {
    placeId?: string | null;
};

export const HeaderSearchButton = ({ placeId }: HeaderSearchButtonProps) => {
    const productHrefFactory = useProductUrlGenerator();
    const [isOpen, setIsOpen] = useState(false);
    const searchOpenEvent = useConsumerTripEventWLSearchTriggered();
    const searchEvent = useConsumerTripEventWLSearch();
    const productClickEvent = useConsumerTripProductClickEventRegistration();

    const handleOnOpen = () => {
        setIsOpen(true);
        void searchOpenEvent({ searchButtonType: "DESTINATION_PRODUCT_SEARCH_NAVBAR_ICON" });
    };

    const dialogContentRef = useRef<HTMLDivElement>(null);

    useCustomDOMEventHandler(dialogContentRef, (subscription) => {
        subscription.on(SearchEvent, (event) => {
            void searchEvent({ phrase: event.phrase, searchType: "DESTINATION_PRODUCT_SEARCH" });
        });

        subscription.on(ProductClickEvent, (event) => {
            void productClickEvent({
                itemId: event.details.item.id,
                itemPosition: event.details.position,
                productOrigin: "DESTINATION_PRODUCT_SEARCH",
            });
        });
    });

    const handleOnClose = () => setIsOpen(false);

    return (
        <>
            {isOpen && (
                <ProductSearchModal
                    placeId={placeId}
                    contentRef={dialogContentRef}
                    productHrefFactory={productHrefFactory}
                    ProductCard={BookingProductCardV2}
                    isOpen={isOpen}
                    onClose={handleOnClose}
                    filters={{ synchronisationSources: ["VIATOR", "MUSEMENT", "TIQETS"] }}
                />
            )}
            <IconButton onClick={handleOnOpen} color="inherit">
                <SearchIcon />
            </IconButton>
        </>
    );
};
